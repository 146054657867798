<template>
  <section
    class="invoice-preview-wrapper"
  >
    <div
      class="invoice-preview d-flex flex-wrap"
    >
      <b-overlay
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <div
          v-if="showDefaultLayout"
          id="invoice-preview-col"
          class="invoice-preview-col"
        >
          <b-card
            id="invoice-preview-card"
            no-body
            class="invoice-preview-card resizable"
            style="width:930px; min-height:81vh;"
          >
            <!-- Header -->
            <b-card-body
              class="invoice"
            >
              <b-row
                id="invoiceTableParent"
                class="d-flex flex-wrap"
              >
                <table
                  id="invoiceTable"
                  class="table-border resizable"
                  style="width:430px;"
                >
                  <tr
                    id="row1"
                    class="table-padding resizable"
                    @click="onElementClick($event)"
                  >
                    <td>
                      <b-img
                        class="dn-brand-logo resize-drag"
                        :src="courier.company_logo ? `${bucketUrl}${courier.company_logo}` : defaultCompanyLogo"
                        alt="logo"
                        @click="onElementClick($event)"
                      />
                    </td>
                    <td>
                      <div style="float: right">
                        <h5
                          class="dn-waybill draggable"
                          @click="onElementClick($event)"
                        >
                          Waybill ID : -- waybill_number --
                        </h5>
                        <div
                          id="barcode"
                          style="padding-right:10px;"
                          class="draggable"
                          @click="onElementClick($event)"
                        >
                          <Barcode
                            id="CF000000"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    id="row2"
                    class="table-padding resizable"
                    height="110px"
                    @click="onElementClick($event)"
                  >
                    <td>
                      <h4
                        class="dn-business-name draggable"
                        @click="onElementClick($event)"
                      >
                        {{ courier.company_name }}
                      </h4>
                      <span
                        class="dn-business-detail draggable"
                        @click="onElementClick($event)"
                      > {{ courier.company_address }} </span>
                      <br>
                      <span
                        class="dn-business-detail draggable"
                        @click="onElementClick($event)"
                      > {{ courier.company_phone }} </span>
                    </td>
                    <td>
                      <div
                        id="qrcode"
                        class="draggable"
                        style="float: right"
                        @click="onElementClick($event)"
                      >
                        <QRcode
                          id="CF000000"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr class="dn-border-bottom">
                    <td colspan="2">
                      <table width="100%">
                        <!-- row01 -->
                        <tr
                          id="subRow1"
                          class="dn-border-top resizable"
                          @click="onElementClick($event)"
                        >
                          <td
                            class="dn-border-right resizable"
                          >
                            <h4
                              class="dn-sub-header draggable"
                              contenteditable
                              @click="onElementClick($event)"
                            >
                              Merchant Details
                            </h4>
                          </td>
                          <td class="resizable">
                            <h4
                              class="dn-sub-header draggable"
                              contenteditable
                              @click="onElementClick($event)"
                            >
                              Customer Details
                            </h4>
                          </td>
                        </tr>
                        <!-- row02 -->
                        <tr
                          id="subRow2"
                          class="dn-border-top resizable"
                          @click="onElementClick($event)"
                        >
                          <td
                            class="dn-border-right resizable"
                          >
                            <div class="dn-valign">
                              <h5
                                class="dn-content-header draggable"
                                contenteditable
                                @click="onElementClick($event)"
                              >
                                Name
                              </h5>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              >-- business_name --</span>
                            </div>
                            <div class="dn-valign">
                              <h5
                                class="dn-content-header draggable"
                                contenteditable
                                @click="onElementClick($event)"
                              >
                                Address
                              </h5>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              >
                                -- address_line_1 --
                              </span>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              >
                                -- address_line_2 --
                              </span>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              >
                                -- address_line_3 --
                              </span>
                            </div>
                            <div class="dn-valign">
                              <h5
                                class="dn-content-header draggable"
                                contenteditable
                                @click="onElementClick($event)"
                              >
                                Telephone
                              </h5>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              >-- phone --</span>
                            </div>
                          </td>
                          <td class="resizable">
                            <div class="dn-valign">
                              <h5
                                class="dn-content-header draggable"
                                contenteditable
                                @click="onElementClick($event)"
                              >
                                Name
                              </h5>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              >-- customer_name --</span>
                            </div>
                            <div class="dn-valign">
                              <h5
                                class="dn-content-header draggable"
                                contenteditable
                                @click="onElementClick($event)"
                              >
                                Address
                              </h5>
                              <span
                                class="dn-content text-break draggable"
                                @click="onElementClick($event)"
                              >-- customer_address --</span>
                            </div>
                            <div class="dn-valign">
                              <h5
                                class="dn-content-header draggable"
                                contenteditable
                                @click="onElementClick($event)"
                              >
                                Telephone
                              </h5>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              >-- concated_phone_number --</span>
                            </div>
                          </td>
                        </tr>
                        <!-- row03 -->
                        <tr
                          id="subRow3"
                          class="dn-border-top resizable"
                          @click="onElementClick($event)"
                        >
                          <td colspan="2">
                            <h4
                              class="dn-sub-header draggable"
                              contenteditable
                              @click="onElementClick($event)"
                            >
                              Order Details
                            </h4>
                          </td>
                        </tr>
                        <!-- row04 -->
                        <tr
                          id="subRow4"
                          class="dn-border-top resizable"
                          @click="onElementClick($event)"
                        >
                          <td
                            class="dn-border-right resizable"
                            style="padding-bottom:10px;vertical-align:top;"
                          >
                            <div style="margin-top:6px">
                              <h5
                                class="dn-content-header draggable"
                                contenteditable
                                @click="onElementClick($event)"
                              >
                                Order Number
                              </h5>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              >-- order_no --</span>
                            </div>
                            <div>
                              <h5
                                class="dn-content-header draggable"
                                contenteditable
                                @click="onElementClick($event)"
                              >
                                Order Date
                              </h5>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              >-- created_at --</span>
                            </div>
                            <div>
                              <h5
                                class="dn-content-header draggable"
                                contenteditable
                                @click="onElementClick($event)"
                              >
                                Postal / Zip Code
                              </h5>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              >-- city.postal_code --</span>
                            </div>
                            <div>
                              <h5
                                class="dn-content-header draggable"
                                contenteditable
                                @click="onElementClick($event)"
                              >
                                Weight
                              </h5>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              > -- weight -- </span>
                            </div>
                          </td>
                          <td
                            style="vertical-align:top"
                            class="resizable"
                          >
                            <div style="height:70px;margin-top:6px">
                              <h5
                                class="dn-content-header draggable"
                                contenteditable
                                @click="onElementClick($event)"
                              >
                                Description
                              </h5>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              >
                                -- description --
                              </span>
                            </div>
                            <div style="margin-top:10px">
                              <h5
                                class="dn-content-header draggable"
                                contenteditable
                                @click="onElementClick($event)"
                              >
                                City
                              </h5>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              >
                                -- city.name --
                              </span>
                            </div>
                            <div>
                              <h5
                                class="dn-content-header draggable"
                                contenteditable
                                @click="onElementClick($event)"
                              >
                                Total COD
                              </h5>
                              <span
                                class="dn-content draggable"
                                @click="onElementClick($event)"
                              >
                                -- cod --
                              </span>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr
                    id="curfoxLabel"
                    class="dn-border-top"
                  >
                    <td
                      colspan="100%"
                      class="curfox-label font-small-2 draggable"
                    >
                      Powered By Curfox.com
                    </td>
                  </tr>
                </table>
              </b-row>
            <!--        </div>-->
            </b-card-body>
          </b-card>
        </div>
      </b-overlay>
      <div
        id="custom-invoice-preview-col"
        class="invoice-preview-col"
      />

      <!-- Right Col: Card -->
      <b-col
        class="invoice-actions"
      >
        <b-card>
          <h4
            style="margin-top:5px"
          >
            Options
          </h4>
          <div
            class="d-flex flex-wrap mt-2"
            style="margin-bottom:5px;"
          >
            <div class="mr-1 mb-1">
              <b-tooltip
                target="delete_button"
                triggers="hover"
                variant="danger"
              >
                Delete Selection
              </b-tooltip>
              <div id="delete_button">
                <b-button
                  variant="outline-danger"
                  class="bg-darken-2"
                  :disabled="selectedElement === null"
                  size="sm"
                  @click="onDelete()"
                >
                  <mdicon
                    name="DeleteSweepOutline"
                    size="15"
                  />
                </b-button>
              </div>
            </div>
            <div class="mr-1 mb-1">
              <b-tooltip
                target="add_row_button"
                triggers="hover"
                variant="primary"
              >
                Add Row
              </b-tooltip>
              <b-button
                id="add_row_button"
                variant="outline-primary"
                class="bg-darken-2"
                size="sm"
                @click="addRow()"
              >
                <mdicon
                  name="TableRowPlusAfter"
                  size="15"
                />
              </b-button>
            </div>
            <div class="mr-1 mb-1">
              <b-tooltip
                target="add_vd_button"
                triggers="hover"
                variant="primary"
              >
                Add Vertical Divider
              </b-tooltip>
              <b-button
                id="add_vd_button"
                variant="outline-primary"
                class="bg-darken-2"
                size="sm"
                @click="addVerticalDivider()"
              >
                <div style="transform: rotate(90deg);">
                  <mdicon
                    name="Minus"
                    size="15"
                  />
                </div>
              </b-button>
            </div>
            <div class="mr-1 mb-1">
              <b-tooltip
                target="add_hd_button"
                triggers="hover"
                variant="primary"
              >
                Add Horizontal Divider
              </b-tooltip>
              <b-button
                id="add_hd_button"
                variant="outline-primary"
                class="bg-darken-2"
                size="sm"
                @click="addHorizontalDivider()"
              >
                <mdicon
                  name="Minus"
                  size="15"
                />
              </b-button>
            </div>
            <div class="mr-1 mb-1">
              <b-tooltip
                target="add_heading1_button"
                triggers="hover"
                variant="primary"
              >
                Header 1
              </b-tooltip>
              <b-button
                id="add_heading1_button"
                variant="outline-primary"
                class="bg-darken-2"
                size="sm"
                @click="addLabel('h1')"
              >
                <mdicon
                  name="FormatHeader1"
                  size="15"
                />
              </b-button>
            </div>
            <div class="mr-1 mb-1">
              <b-tooltip
                target="add_heading2_button"
                triggers="hover"
                variant="primary"
              >
                Header 2
              </b-tooltip>
              <b-button
                id="add_heading2_button"
                variant="outline-primary"
                class="bg-darken-2"
                size="sm"
                @click="addLabel('h2')"
              >
                <mdicon
                  name="FormatHeader2"
                  size="15"
                />
              </b-button>
            </div>
            <div class="mr-1 mb-1">
              <b-tooltip
                target="add_heading3_button"
                triggers="hover"
                variant="primary"
              >
                Header 3
              </b-tooltip>
              <b-button
                id="add_heading3_button"
                variant="outline-primary"
                class="bg-darken-2"
                size="sm"
                @click="addLabel('h3')"
              >
                <mdicon
                  name="FormatHeader3"
                  size="15"
                />
              </b-button>
            </div>
            <div class="mr-1 mb-1">
              <b-tooltip
                target="add_heading4_button"
                triggers="hover"
                variant="primary"
              >
                Header 4
              </b-tooltip>
              <b-button
                id="add_heading4_button"
                variant="outline-primary"
                class="bg-darken-2"
                size="sm"
                @click="addLabel('h4')"
              >
                <mdicon
                  name="FormatHeader4"
                  size="15"
                />
              </b-button>
            </div>
            <div class="mr-1 mb-1">
              <b-tooltip
                target="add_heading5_button"
                triggers="hover"
                variant="primary"
              >
                Header 5
              </b-tooltip>
              <b-button
                id="add_heading5_button"
                variant="outline-primary"
                class="bg-darken-2"
                size="sm"
                @click="addLabel('h5')"
              >
                <mdicon
                  name="FormatHeader5"
                  size="15"
                />
              </b-button>
            </div>
            <div class="mr-1 mb-1">
              <b-tooltip
                target="add_text_button"
                triggers="hover"
                variant="primary"
              >
                Text
              </b-tooltip>
              <b-button
                id="add_text_button"
                variant="outline-primary"
                class="bg-darken-2"
                size="sm"
                @click="addLabel('span')"
              >
                <mdicon
                  name="FormatText"
                  size="15"
                />
              </b-button>
            </div>
          </div>
          <b-row class="">
            <b-col>
              <b-form-checkbox
                id="reversed"
                v-model="showBorders"
                :value="true"
                :unchecked-value="false"
                @input="onShowBorderToggle($event)"
              >
                Show Borders
              </b-form-checkbox>
            </b-col>
          </b-row>
          <div
            v-for="(componentOption) in componentOptions"
            :key="componentOption.title"
          >
            <h5 class="my-1">
              {{ componentOption.title }}
            </h5>
            <b-button-group
              v-for="(option) in componentOption.options"
              :key="option.value"
              size="sm"
              class="mb-1"
            >
              <b-button
                :ref="`${option.value}-btn`"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                :class="'rounded-0'"
                @click="addComponent(option)"
              >
                {{ option.text }}
              </b-button>
            </b-button-group>
          </div>
          <b-overlay
            :opacity="0.5"
            :show="saveLoading"
            rounded="sm"
            blur="10px"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="success"
              class="mb-75 mt-1"
              block
              @click="updateWaybillLayout()"
            >
              Save
            </b-button>
          </b-overlay>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="danger"
            class="mb-75"
            block
            @click="clear()"
          >
            Clear All
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="warning"
            class="mb-75"
            block
            @click="restoreLayout()"
          >
            Restore Default Layout
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75 mt-1"
            block
            @click="testPrint()"
          >
            Test Print
          </b-button>
        </b-card>
      </b-col>
    </div>
  </section>
</template>

<script>
import {
  BCol, BRow, BCard, BCardBody, BButton, BButtonGroup, BImg, BTooltip, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'
import Vue from 'vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Ripple from 'vue-ripple-directive'
import initializeInteraction from '@/libs/interact'
import { bucketUrl, defaultCompanyLogo } from '@/constants/config'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import Barcode from '@/components/print/Barcode.vue'
import QRcode from '@/components/print/QRcode.vue'

const LayoutRepository = RepositoryFactory.get('layout')

export default {
  directives: {
    Ripple,
  },
  components: {
    QRcode,
    Barcode,
    BCol,
    BRow,
    BCard,
    BCardBody,
    BButton,
    BButtonGroup,
    BImg,
    BTooltip,
    BOverlay,
    BFormCheckbox,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      id: null,
      document,
      courier: {},
      dispatchNotes: [],
      bucketUrl,
      defaultCompanyLogo,
      componentOptions: [
        {
          title: 'Business Components',
          options: [
            { text: 'Company Name', value: 'company_name' },
            { text: 'Company Logo', value: 'company_logo' },
            { text: 'Company Address', value: 'company_address' },
            { text: 'Company Phone Number', value: 'company_phone' },
          ],
        },
        {
          title: 'Merchant Components',
          options: [
            { text: 'Merchant Name', value: 'business_name' },
            { text: 'Merchant Address Line 1', value: 'address_line_1' },
            { text: 'Merchant Address Line 2', value: 'address_line_2' },
            { text: 'Merchant Address Line 3', value: 'address_line_3' },
            { text: 'Merchant Phone Number', value: 'phone' },
          ],
        },
        {
          title: 'Customer Components',
          options: [
            { text: 'Customer Name', value: 'customer_name' },
            { text: 'Customer Address', value: 'customer_address' },
            { text: 'Customer Phone Number', value: 'concated_phone_number' },
          ],
        },
        {
          title: 'Order Components',
          options: [
            { text: 'Waybill Number', value: 'waybill_number' },
            { text: 'Barcode', value: 'barcode' },
            { text: 'QRcode', value: 'qrcode' },
            { text: 'Order Number', value: 'order_no' },
            { text: 'Order Date', value: 'created_at' },
            { text: 'Postal/Zip Code', value: 'city.postal_code' },
            { text: 'Weight', value: 'weight' },
            { text: 'Description', value: 'description' },
            { text: 'City', value: 'city.name' },
            { text: 'Warehouse', value: 'warehouse.name' },
            { text: 'COD', value: 'cod' },
            { text: 'Delivery Charge', value: 'delivery_charge' },
          ],
        },
      ],
      loading: false,
      saveLoading: false,
      selectedRow: null,
      selectedElement: null,
      waybillLayout: null,
      showDefaultLayout: false,
      showBorders: true,
    }
  },
  created() {
    this.id = this.$route.params.id
  },
  async mounted() {
    this.courier = JSON.parse(localStorage.getItem('businessSettings'))
    await this.getWaybillLayout()
    this.showDefaultLayout = true
    this.$nextTick(() => {
      localStorage.setItem('defaultWaybillLayout', document.getElementById('invoice-preview-col').innerHTML)
      if (this.waybillLayout) {
        this.showDefaultLayout = false
      }
    })
    if (this.waybillLayout) {
      this.initWaybillLayout()
    } else {
      this.showDefaultLayout = true
    }
    initializeInteraction()
    this.initButtonVisibilities()
  },
  methods: {
    async getWaybillLayout() {
      try {
        this.loading = true
        const { data } = (await LayoutRepository.getWaybillLayoutDetail(this.id))
        this.waybillLayout = data.data.template
      } catch (e) {
        this.showErrorMessage('Couldn\'t fetch waybill layout!')
      }
      this.loading = false
    },
    async updateWaybillLayout() {
      try {
        this.saveLoading = true
        const payload = {
          id: this.id,
          waybill: this.getFineTunedLayout(),
        }
        const res = await LayoutRepository.updateWaybillLayout(payload)
        if (res.status === 200) {
          this.showSuccessMessage('Waybill Layout Updated Successfully!')
        } else {
          this.showErrorMessage('Couldn\'t update waybill layout!')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.saveLoading = false
    },
    initWaybillLayout() {
      const container = document.getElementById('custom-invoice-preview-col')
      container.innerHTML = this.waybillLayout
      const outOfPlaceList = []
      const tableParent = document.getElementById('invoiceTableParent')
      tableParent.children.forEach(child => {
        if (child.tagName !== 'TABLE') {
          const copy = child.cloneNode(true)
          outOfPlaceList.push(copy)
        } else {
          document.getElementById('invoiceTableParent').innerHTML = child.outerHTML
        }
      })
      outOfPlaceList.forEach(child => {
        document.getElementById('invoiceTable').insertAdjacentElement('afterbegin', child)
      })
      document.querySelectorAll('.draggable,.resize-drag,.resizable,.resize-drag-horizontal,.resize-drag-vertical').forEach(element => {
        element.addEventListener('click', event => {
          this.onElementClick(event)
        })
      })
      this.showBorders = !this.waybillLayout.includes('no-borders')
      if (this.waybillLayout.includes('barcode_CF000000')) {
        const barcodeStyle = document.getElementById('barcode_CF000000').style.cssText
        new Vue({
          ...Barcode,
          propsData: {
            id: 'CF000000',
          },
        }).$mount('#barcode_CF000000')
        document.getElementById('barcode_CF000000').style.cssText = barcodeStyle
        document.getElementById('barcode_CF000000').classList.add('draggable')
        document.getElementById('barcode_CF000000').addEventListener('click', event => {
          this.onElementClick(event)
        })
      }
      if (this.waybillLayout.includes('qrcode_CF000000')) {
        const qrStyle = document.getElementById('qrcode_CF000000').style.cssText
        new Vue({
          ...QRcode,
          propsData: {
            id: 'CF000000',
          },
        }).$mount('#qrcode_CF000000')
        document.getElementById('qrcode_CF000000').style.cssText = qrStyle
        document.getElementById('qrcode_CF000000').classList.add('draggable')
        document.getElementById('qrcode_CF000000').addEventListener('click', event => {
          this.onElementClick(event)
        })
      }
    },
    getFineTunedLayout() {
      let layout = document.getElementById(this.showDefaultLayout ? 'invoice-preview-col' : 'custom-invoice-preview-col').innerHTML
      layout = layout.replaceAll('outline: rgb(65, 134, 245) solid 1px;', '')
      return layout
    },
    addComponent(option) {
      const table = document.getElementById('invoiceTable')
      let component = ''
      let componentId = ''
      if (option.value === 'barcode') {
        componentId = 'barcode_placeholder'
        component = `<div id="${componentId}" class="customComponent"/>`
      } else if (option.value === 'qrcode') {
        componentId = 'qrcode_placeholder'
        component = `<div id="${componentId}" class="customComponent"/>`
      } else if (option.value === 'company_logo') {
        componentId = option.value
        component = `<img id="${componentId}" class="dn-brand-logo resize-drag customComponent" style="position:absolute; z-index:100;" src="${this.courier.company_logo ? `${this.bucketUrl}${this.courier.company_logo}` : this.defaultCompanyLogo}" alt="logo"/>`
      } else {
        let renderValue
        componentId = option.value
        if (option.value.includes('company_')) {
          renderValue = this.courier[componentId]
        } else {
          renderValue = `-- ${componentId} --`
        }
        component = `<span id="${componentId}" class="dn-content draggable customComponent" style="position:absolute; z-index:1;">${renderValue}</span>`
      }
      table.insertAdjacentHTML('afterbegin', component)
      document.getElementById(componentId).addEventListener('click', event => {
        this.onElementClick(event)
      })
      let componentInstance
      if (option.value === 'barcode') {
        componentInstance = new Vue({
          ...Barcode,
          propsData: {
            id: 'CF000000',
          },
        }).$mount('#barcode_placeholder')
      } else if (option.value === 'qrcode') {
        componentInstance = new Vue({
          ...QRcode,
          propsData: {
            id: 'CF000000',
          },
        }).$mount('#qrcode_placeholder')
      }
      if (componentInstance) {
        componentInstance.$el.classList.add('draggable')
        componentInstance.$el.style.position = 'absolute'
        componentInstance.$el.style.zIndex = '100'
        componentInstance.addEventListener('click', event => {
          this.onElementClick(event)
        })
      }
      this.$refs[`${option.value}-btn`][0].disabled = true
    },
    addLabel(tag) {
      const uniq = `label_id${(new Date()).getTime()}`
      const table = document.getElementById('invoiceTable')
      const component = `<${tag} id="${uniq}" class="${tag === 'span' ? 'dn-content-header customComponent' : 'customComponent'} draggable" style=" position:absolute; z-index:100;" contenteditable> Label </${tag}>`
      table.insertAdjacentHTML('afterbegin', component)
      document.getElementById(uniq).addEventListener('click', event => {
        this.onElementClick(event)
      })
    },
    addRow() {
      const uniq = `row_id${(new Date()).getTime()}`
      const table = document.getElementById('curfoxLabel')
      const component = `<tr id="${uniq}" class="dn-border-top resizable"> <td class="resizable" colspan="100%" style="padding:15px;width:100%;colspan="100%"; background-color:#FF0000;"></td></tr>`
      table.insertAdjacentHTML('beforebegin', component)
      const newRowElement = document.getElementById(uniq)
      newRowElement.addEventListener('click', event => {
        this.onElementClick(event)
      })
    },
    addVerticalDivider() {
      const uniq = `divider_id${(new Date()).getTime()}`
      const table = document.getElementById('invoiceTable')
      const component = `<div id="${uniq}" class="customComponent resize-drag-vertical" style=" position:absolute; z-index:100; width:3px;height:150px; background-color:#000000;"></div>`
      table.insertAdjacentHTML('afterbegin', component)
      document.getElementById(uniq).addEventListener('click', event => {
        this.onElementClick(event)
      })
    },
    addHorizontalDivider() {
      const uniq = `divider_id${(new Date()).getTime()}`
      const table = document.getElementById('invoiceTable')
      const component = `<div id="${uniq}" class="customComponent resize-drag-horizontal" style=" position:absolute; z-index:100; width:150px;height:3px; background-color:#000000;"></div>`
      table.insertAdjacentHTML('afterbegin', component)
      document.getElementById(uniq).addEventListener('click', event => {
        this.onElementClick(event)
      })
    },
    onDelete() {
      if (this.selectedElement) {
        if (this.$refs[`${this.selectedElement.id}-btn`]) {
          this.$refs[`${this.selectedElement.id}-btn`][0].disabled = false
        }
        this.selectedElement.remove()
        this.selectedElement = null
        this.initButtonVisibilities()
      }
    },
    onElementClick(event) {
      if (event.currentTarget.id.includes('row') || event.currentTarget.id.includes('subRow')) {
        if (document.getElementById(this.selectedRow)) {
          document.getElementById(this.selectedRow).style.outline = 'none'
        }
        this.selectedRow = event.currentTarget.id
        // eslint-disable-next-line no-param-reassign
        event.currentTarget.style.outline = 'solid #4186f5 1px'
      } else if (event.currentTarget.classList.contains('draggable') || event.currentTarget.classList.contains('resize-drag') || event.currentTarget.classList.contains('resize-drag-vertical') || event.currentTarget.classList.contains('resize-drag-horizontal') || event.currentTarget.id.includes('barcode') || event.currentTarget.id.includes('qrcode')) {
        if (this.selectedElement) {
          this.selectedElement.style.outline = 'none'
        }
        this.selectedElement = event.currentTarget
        this.selectedElement.style.outline = 'solid #4186f5 1px'
      }
    },
    onShowBorderToggle(event) {
      document.querySelectorAll('table,td,tr').forEach(element => {
        if (event) {
          element.classList.remove('no-borders')
        } else {
          element.classList.add('no-borders')
        }
      })
    },
    initButtonVisibilities() {
      if (document.getElementById('qrcode_CF000000')) {
        this.$refs['qrcode-btn'][0].disabled = true
      } else {
        this.$refs['qrcode-btn'][0].disabled = false
      }
      if (document.getElementById('barcode_CF000000')) {
        this.$refs['barcode-btn'][0].disabled = true
      } else {
        this.$refs['barcode-btn'][0].disabled = false
      }
    },
    clear() {
      const table = document.getElementById('invoiceTable')
      this.removeChildren(table)
      if (table.firstChild.tagName === 'TBODY') this.removeChildren(table.firstChild)
      document.getElementById('invoiceTableParent').innerHTML = document.getElementById('invoiceTable').outerHTML
      this.$refs['barcode-btn'][0].disabled = false
      this.$refs['qrcode-btn'][0].disabled = false
    },
    restoreLayout() {
      this.waybillLayout = localStorage.getItem('defaultWaybillLayout')
      this.initWaybillLayout()
    },
    removeChildren(parent) {
      const children = parent.childElementCount
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < children - 1; i++) {
        parent.removeChild(parent.firstChild)
      }
    },
    async testPrint() {
      window.print()
    },
  },
}
</script>

<style lang="scss">
@import "../../@core/scss/base/pages/app-invoice.scss";
@page {
    size: A4 portrait;
    margin: 10mm;
}
.rounded-left {
   border-radius: 5px 0px 0px 5px !important;
}
.rounded-right {
   border-radius: 0px 5px 5px 0px !important;
}
.dn-business-name {
  padding-left: 15px;
  font-size: 17px;
  font-weight: bolder;
  color: black;
}
.dn-business-detail {
  display: inline-block;
  padding-left: 15px;
  font-size: 13px;
  color: black;
}
.dn-brand-logo {
  display: block;
  width: 90px;
  height: 90px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  float: left;
  border-radius: 20px;
}
.dn-border-top {
  border-top: solid black 2px;
}
.dn-border-right {
  border-right: solid black 2px;
}
.dn-border-bottom {
  border-bottom: solid black 2px;
}
.dn-border-left {
  border-left: solid black 2px;
}
.no-borders {
  border: solid rgba(0, 0, 0, 0) 0px !important;
}
.dn-waybill {
  padding: 10px 5px 5px 0;
  font-weight: bolder;
  color: black;
  text-align: right;
  font-size: 15px;
}
.dn-sub-header {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bolder;
  color: black;
}
.dn-city-cod {
  padding: 5px 0 0 5px;
  font-size: 18px;
  font-weight: bolder;
  color: black;
  text-align: center;
}
.dn-content-header {
  padding: 3px 0 0 5px;
  font-size:13px;
  font-weight: bolder;
  margin-bottom: 2px;
  color: black;
}
.dn-content {
  padding-left: 5px;
  font-size: 13px;
  display:inline-block;
  color: black;
  font-weight: 400;
}
.dn-valign {
  vertical-align: top;
}
.invoice-preview-col {
  overflow-y: auto;
}
.table-border {
  border: solid black 2px;
  margin-bottom:15px;
  margin-top:10px
}
table {
    table-layout:auto !important;
}
.curfox-label {
    padding: 2px;
    text-align: center;
    font-weight: bolder;
    column-span: all !important;
}
h1,h2,h3,h4,h5,span {
  outline: 0px solid transparent;
}
.black-components,
.dn-business-name,
.dn-business-detail,
.dn-waybill,
.dn-sub-header,
.dn-city-cod,
.dn-content-header,
.dn-content,
.dn-valign,
.curfox-label {
  font-family: "Arial", sans-serif;
}
@media print {
  body {
    width: 210mm;
    height: 296mm;
    overflow:hidden;
  }
  * {
    outline: none !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
  #invoiceTable {
    outline: solid black 3px !important;
    border: none !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content-header {
    display: none;
  }
  .content.app-content {
    margin: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
    overflow:hidden;
  }
  .customizer-toggle {
    display: none !important;
  }
  // Invoice Specific Styles
  .invoice-preview-wrapper {
    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .invoice-preview {
    display: block;
  }
  .invoice {
    position: relative;
    display: block;
  }
  .invoice-preview-col {
    display: block;
  }
  .invoice-preview-card {
    display: block;
    break-inside: avoid;
    page-break-inside: avoid;
    break-after: always;
    page-break-after: always;
  }
  section span, section p, section div {
    font-weight: 900 !important;
  }
}
</style>
