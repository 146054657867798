import interact from 'interactjs'

const position = { x: 0, y: 0 }

const dragListener = {
  move(event) {
    position.x += event.dx
    position.y += event.dy
    // eslint-disable-next-line no-param-reassign
    event.target.style.transform = `translate(${position.x}px, ${position.y}px)`
  },
}

const resizeListener = {
  move(event) {
    let pos = event.target.style.transform.replaceAll('translate(', '').replaceAll('px', '').replaceAll(')', '')
    pos = pos.split(',')

    const x = (parseFloat(pos[0]) || 0) + event.deltaRect.left
    const y = (parseFloat(pos[1]) || 0) + event.deltaRect.top

    Object.assign(event.target.style, {
      width: `${event.rect.width}px`,
      height: `${event.rect.height}px`,
      transform: `translate(${x}px, ${y}px)`,
    })

    Object.assign(event.target.dataset, { x, y })
  },
}

const initializeInteraction = () => {
  interact('.draggable').draggable({
    listeners: dragListener,
  })

  interact('.resizable')
    .resizable({
      edges: {
        top: false, left: true, bottom: true, right: true,
      },
      margin: 5,
      listeners: resizeListener,
    })

  interact('.resize-drag')
    .draggable({
      listeners: dragListener,
    })
    .resizable({
      edges: {
        top: false, left: false, bottom: true, right: true,
      },
      margin: 5,
      listeners: resizeListener,
    })

  interact('.resize-drag-vertical')
    .draggable({
      listeners: dragListener,
    })
    .resizable({
      edges: {
        top: true, left: false, bottom: true, right: false,
      },
      margin: 5,
      listeners: resizeListener,
    })

  interact('.resize-drag-horizontal')
    .draggable({
      listeners: dragListener,
    })
    .resizable({
      edges: {
        top: false, left: true, bottom: false, right: true,
      },
      margin: 5,
      listeners: resizeListener,
    })
}

export default initializeInteraction
